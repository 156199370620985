import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { RoutesService } from 'src/app/api/routes.service';
import { BcAccountsService, DistrictDetail, School, SchoolDetail } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { MaFinalResultsViews } from 'src/app/ui-ministryadmin/ma-final-results/views';
import { SaFinalResultsViews } from 'src/app/ui-schooladmin/sa-final-results/views';
import { BCFSASchoolAdminView } from 'src/app/ui-schooladmin/view-schooladmin-dashboard/data/views';
import { TestWindowFileSlug } from 'src/app/types/bc-test-window-file';
import { ngHasInputChanged } from 'src/app/ui-schooladmin/sa-accounts-students/sa-accounts-students-enrolled/util';
import { MyBoardService } from 'src/app/ui-dist-admin/my-board.service';
import { HeaderLayoutEvent } from '../bc-header-layout/bc-header-layout.component';

@Component({
  selector: 'fsa-final-results-table',
  templateUrl: './fsa-final-results-table.component.html',
  styleUrls: ['./fsa-final-results-table.component.scss']
})
export class FsaFinalResultsTableComponent implements OnInit {
  @Input() headerLayoutEvent: HeaderLayoutEvent | null;
  @Input() selectedView: SaFinalResultsViews;
  @Input() accountType: AccountType;
  @Input() districtDetail: DistrictDetail;
  @Input() schools: School[];
  @Input() schoolDetail: SchoolDetail;
  @Input() testWindow: TestWindow;
  @Input() isIrtReady: boolean; // deprecated usage in this component, not used
  @Input() isMonitoringReport: boolean;

  isLoading: boolean = false;
  isPageLoaded: boolean = false;

  isDownloadingAll: boolean = false;

  sldIncludeScaled: boolean = true;

  SaFinalResultsViews = SaFinalResultsViews;

  isFsaEaoMinistry: boolean = false;
  isFsaMinistryAdmin: boolean = false;
  isFsaSchoolAdmin: boolean = false;
  isFsaDistrictAdmin: boolean = false;
  isFisaAdmin: boolean = false;
  odiFiles = [];

  constructor(
    public auth: AuthService,
    private router: Router,
    private lang: LangService,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    private bcAccounts: BcAccountsService,
    private routes: RoutesService,
    private myBoard: MyBoardService,
  ) { }

  ngOnInit(): void {
    this.auth.user().subscribe(async user => {
      if (user) {
        this.isFsaEaoMinistry = user.accountType === AccountType.BC_FSA_ADMIN_EAO;
        this.isFsaMinistryAdmin = user.accountType === AccountType.BC_FSA_MINISTRY_ADMIN;
        this.isFsaSchoolAdmin = user.accountType === AccountType.BC_FSA_SCHOOL_ADMIN;
        this.isFsaDistrictAdmin = user.accountType === AccountType.BC_FSA_DIST_ADMIN;
        this.isFisaAdmin = await this.auth.isFisaAdmin(user.uid);
        this.isPageLoaded = true;
      }

      if (this.isFsaEaoMinistry) {
        this.getFsaOdiFiles();
      }
      if (this.auth.isDistrictAdmin()){
        this.myBoard.sub().subscribe(infos => {
          if (!infos || infos.length < 1) return;
          const district_group_id = infos[0].group_id;
          this.bcAccounts.getDistrictDetail(district_group_id).then(districtDetail => {
            this.districtDetail = districtDetail;
          })
        })  
        this.getCurrentFSAMarkingSession();
      }
      if (this.auth.isMinistryAdmin()){
        this.myBoard.sub().subscribe(infos => {
          if (!infos || infos.length < 1) return;
          const district_group_id = infos[0].group_id;
          this.bcAccounts.getDistrictDetail(district_group_id).then(districtDetail => {
            this.districtDetail = districtDetail;
          })
        })  
        this.getCurrentFSAMarkingSession();
      }
    })
  }
  
  selectSchoolLevelDataReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectIndividualStudentResultsReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectSelfReflectionReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.SELF_REFLECTION_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectStudentAnalysisReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.STUDENT_RESULTS)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectProvincialResponseReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.PROVINCIAL_RESPONSE_TRACKER)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectAssessmentKeysReport()
  {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.ASSESSMENT_KEYS)], 
    {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  selectProficiencyLevelReport() {
    this.router.navigate([this.getViewRoute(SaFinalResultsViews.PROFICIENCY_DISTRIBUTION_REPORT)], {
      queryParams: this.getQueryParams(),
      relativeTo: this.route,
    });
  }

  getQueryParams() {
    const params = {
      ...this.route.snapshot.queryParams,
    };
    console.log(this.route.snapshot.queryParams);
    if (this.schoolDetail && this.schoolDetail.groupId > 0) {
      params.school = this.schoolDetail.groupId;
    };
    return params;
  }


  getViewRoute(viewSlug: string) {
    let route = this.getBaseRoute();
    if (viewSlug === 'dashboard') {
      return route + '/dashboard';
    }
    if (!(this.auth.isMinistryAdmin() && (viewSlug === SaFinalResultsViews.DISCREPANCY_REPORT || viewSlug === SaFinalResultsViews.MONITORING_REPORT || viewSlug === SaFinalResultsViews.PROVINCIAL_RESPONSE_TRACKER))){
      route = route + '/' + BCFSASchoolAdminView.BC_FSA_FINAL_REPORT;
    }
    if (this.auth.isMinistryAdmin() || this.isFsaEaoMinistry) {
      if (viewSlug === SaFinalResultsViews.DISCREPANCY_REPORT || viewSlug === SaFinalResultsViews.MONITORING_REPORT
          || viewSlug === SaFinalResultsViews.PROVINCIAL_RESPONSE_TRACKER) {
        route += '/' + MaFinalResultsViews.MONITORING_REPORT;
        route += '/' + MaFinalResultsViews.RESULTS_REPORT;
      } else {
        route += '/' + MaFinalResultsViews.RESULTS_REPORT;
      }
    }
    if (viewSlug === BCFSASchoolAdminView.BC_FSA_FINAL_REPORT) {
      return route;
    }
    return route + '/' + viewSlug;
  }

  getBaseRoute() {
    let account: string;
    if (this.auth.isDistrictAdmin(this.accountType)) {
      account = AccountType.DIST_ADMIN;
    } else if (this.auth.isSchoolAdmin(this.accountType)) {
      account = AccountType.SCHOOL_ADMIN;
    } else if (this.auth.isMinistryAdmin(this.accountType)) {
      account = AccountType.MINISTRY_ADMIN;
    } else {
      throw new Error("Unknown account type");
    }
    return `/${this.lang.c()}/${account}/bc-fsa`;
  }

  exportSchoolLevelDataReport() {
    if (!this.schoolDetail) {
      this.loginGuard.quickPopup("Please select a school to export the School Level Data Report.");
      return;
    };
    
    // always get the raw SLD report on the table export; if a ministry admin wants scaled, they can click the report,
    // select scaled and then export.

    // we always want raw SLD report for SAs anyways (it's a BR)
    this.bcAccounts.exportSchoolLevelDataReport(this.schoolDetail.groupId, this.testWindow, false);
  }

  async exportAdminFeedbacks() {
    const filename = 'FSA-Feedback';
    const url = this.auth.reportFilePath(this.routes.SCHOOL_ADMIN_QUESTIONNAIRE, JSON.stringify({
      query: {
          isExport: true,
      }
    }), filename);
    window.open(url, '_blank');
  }

  exportIndividualStudentResultsReports() {
    let route: string;
    if (this.auth.isMinistryAdmin()) {
      route = `/final_results/results_report?testWindow=${this.testWindow.id}&bulk=1`;
    } else {
      route = `/final_results?testWindow=${this.testWindow.id}&bulk=1`;
    }
    if(this.schoolDetail) {
      route+='&school='+this.schoolDetail.groupId
    }
    window.open('#' + this.getBaseRoute() + route, '_blank');
    // this.isDownloadingAll = true;
    // this.auth.apiCreate('public/bcg-scan/individual-student-results-report', {
    //   districtGroupId:this.districtDetail.groupId,
    //   districtForeignId:this.districtDetail.foreignId,
    //   testWindowId:this.testWindow.id,
    //   isIrtReady:this.isIrtReady,
    // }).then(response => {
    //   // response.data -> response data base64 encoded
    //   // decoding the data via atob()
    //   const byteArray = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
    //   return new Blob([byteArray], {type: 'application/pdf'});
    // })
    // .then(blob => {
    //   saveAs(blob, 'Individual Student Results Report.pdf');
    //   this.isDownloadingAll = false;
    // });
  }

  isScoreEntryAdmin(): boolean {
    return this.auth.isScoreEntrySchoolAdmin() || this.auth.isScoreEntryDistrictAdmin();
  }

  isDistrictSelected() {
    return this.districtDetail?.groupId > 0;
  }

  isSchoolSelected() {
    return this.schoolDetail?.groupId > 0;
  }

  isDownloadAllDisabled() {
    return this.isLoading || !(this.isDistrictSelected() && this.isSchoolSelected());
  }

  isCurrentUserFsaMinistryAdmin() {
    return this.auth.isFsaMinistryAdmin();
  }

  isCurrentUserSchoolAdmin() {
    return this.auth.isSchoolAdmin();
  }

  isCurrentUserFsaDistrictAdmin() {
    return this.auth.isFsaDistrictAdmin();
  }

  public isDownloadAllAvailable() {
    return this.isSchoolSelected();
  }

  async downloadFile(testWindowFileId: number) {
    try {
      const link = await this.auth.apiGet(this.routes.BC_ADMIN_TEST_WINDOW_FILE, testWindowFileId);

      window.open(link);
    } catch {}
  }

  async getFsaOdiFiles(): Promise<void> {
    if (!this.testWindow) return;

    try {
      const records = await this.auth.apiFind(this.routes.BC_ADMIN_TEST_WINDOW_FILE, {
        query: {
          testWindowId: this.testWindow.id,
          slug: TestWindowFileSlug.FSA_ODI
        }
      })

      if (records.length) {
        for (const record of records) {
          const { id, meta } = record;

          this.odiFiles.push({
            caption: this.lang.tra(meta.caption),
            description: this.lang.tra(meta.description),
            testWindowFileId: id
          })
        }
      }
    } catch {}
  }

  isEAO(){
    return this.auth.checkUserAccountType(AccountType.BC_FSA_ADMIN_EAO);   
  }

  ngOnChanges(change) {
    if(ngHasInputChanged(change.testWindow, "id")){
      if(this.auth.isDistrictAdmin()) this.getCurrentFSAMarkingSession();
    }
  }

  markingSession;
  async getCurrentFSAMarkingSession(){
    if(!this.testWindow) return;
    this.markingSession = await this.auth.apiFind(this.routes.BC_ADMIN_PROV_RESP_TRACKER, {
      query: {
        test_window_id: this.testWindow.id,
        getMarkingSession: 1
      }
    })
    if(this.markingSession.length === 0) this.markingSession = null;
  }

  selectDiscrepancyReport(monitoring: boolean = false){
    if(!this.markingSession) return;
    this.router.navigate([
      monitoring ? this.getViewRoute(SaFinalResultsViews.MONITORING_REPORT) : 
      this.getViewRoute(SaFinalResultsViews.DISCREPANCY_REPORT)], {queryParamsHandling: 'preserve' })
  }
}
