<button class="button is-info" (click)="submit()" [disabled]="isLoading">Load Data</button>

<div *ngIf="isLoading" style="height:100%;display:flex;justify-content:center;align-items:center;">
  <loading-indicator></loading-indicator><br/>
  <p>Loading schools ({{numSchoolsLoaded}} / {{numSchoolsToLoad}})</p>
</div>
<ng-container *ngIf="!isLoading && allowToAccess">
  <h1 *ngIf="showHeader" style="font-weight: bold;">Proficiency Distribution Report: {{ getAdministrationYear() }}</h1>

  <button class="button is-info dont-print" (click)="printReport()">Print</button>

  <div style="margin: 1rem 0" *ngFor="let row of data">
      <p style="font-weight: bold;">Grade {{row.grade}} {{ testWindow.schoolYear }}</p>
      <p >{{capitalize(row.subject)}}</p>

      <div style="display: flex; margin-top: 1rem; gap: 3rem;">
          <chart
              [id]="row.grade+row.subject"
              [labels]="chartLabels"
              [data]="row.numbers"
          >
          </chart>

          <table class="table">
              <thead class="table-header">
                <tr>
                  <th scope="col" colspan="1" style="text-align: center; border: none;"></th>
                  <th scope="col" colspan="2" style="text-align: center; border-left: 1px black solid; border-bottom: 1px black solid;">Participation Rate – Writers Only</th>
                </tr>

                <tr>
                  <th scope="col" style="text-align: center; border-bottom: 1px black solid;"></th>
                  <th scope="col" style="text-align: center;" class="table-row-header">#</th>
                  <th scope="col" style="text-align: center;" class="table-row-header">%</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th class="table-row-header" style="text-align: center;">Emerging</th>
                  <td class="table-row-data">{{ row.numbers[0] }}</td>
                  <td class="table-row-data">{{ getPercentageDisplay(row.numbers[0], row.sum) }}</td>
                </tr>
                <tr>
                  <th class="table-row-header" style="text-align: center;">On Track</th>
                  <td class="table-row-data">{{ row.numbers[1] }}</td>
                  <td class="table-row-data">{{ getPercentageDisplay(row.numbers[1], row.sum) }}</td>
                </tr>
                <tr>
                  <th class="table-row-header" style="text-align: center;">Extending</th>
                  <td class="table-row-data">{{ row.numbers[2] }}</td>
                  <td class="table-row-data">{{ getPercentageDisplay(row.numbers[2], row.sum) }}</td>
                </tr>
              </tbody>
            </table>
      </div>
  </div>

  <p *ngIf="isScaledResultReady">
    <b>
      <tra slug="sa_sr_note"></tra>
    </b>
    <tra slug="proficiency_distribution_report_disclaimer"></tra>
  </p>
</ng-container>

<ng-container *ngIf="!isLoading && !allowToAccess">
  <p>No Data available.</p>
</ng-container>