<div class="fsa-final-results-table" *ngIf="isPageLoaded">
    <div class="sa-final-results-landing" *ngIf="!selectedView">
        <div class="sa-final-results-intro">
            <tra-md [slug]="isEAO() ? 'sa_eao_fr_intro' : 'sa_fr_intro'"></tra-md>
        </div>

        <table class="table is-auto-width">
            <tr class="header-row">
                <td>
                    <tra slug="sa_sr_report_name"></tra>
                </td>
                <td>
                    <tra slug="sa_sr_desc"></tra>
                </td>
                <td>
                    <tra slug="sa_export"></tra>
                </td>
            </tr>
            <tr class="report-row" *ngIf="!isMonitoringReport && !isFisaAdmin">
                <td>
                    <a (click)="selectSchoolLevelDataReport()">
                        <tra slug="sc_sldr_title"></tra>
                    </a>
                </td>
                <td>
                    <span *ngIf="isCurrentUserFsaMinistryAdmin()">
                        <tra slug="sc_sldr_desc"></tra>
                    </span>

                    <span *ngIf="!isCurrentUserFsaMinistryAdmin()">
                        <tra slug="sc_sldr_desc_no_scaled"></tra>
                    </span>
                </td>
                <td>
                    <ng-container>
                        <button class="export-button" (click)="exportSchoolLevelDataReport()" [disabled]="isLoading">
                            <span class="icon"></span>
                            <span class="title">
                                <tra slug="sa_export"></tra>
                            </span>
                        </button>
                    </ng-container>
                </td>
            </tr>

            <!-- Proficiency Distribution Report -->
            <tr class="report-row" *ngIf="!isFisaAdmin">
                <td>
                    <a (click)="selectProficiencyLevelReport()">
                        <tra slug="proficiency_distribution_report_title"></tra>
                    </a>
                </td>
                <td>
                    <span *ngIf="isCurrentUserFsaMinistryAdmin()">
                        <tra slug="proficiency_distribution_report_desc"></tra>
                    </span>

                    <span *ngIf="!isCurrentUserFsaMinistryAdmin()">
                        <tra slug="proficiency_distribution_report_school_level_desc"></tra>
                    </span>
                </td>
                <td>
                </td>
            </tr>

            <tr class="report-row" *ngIf="!isScoreEntryAdmin() && !isFsaEaoMinistry && !isMonitoringReport && !isFisaAdmin">
                <td>
                    <a (click)="selectIndividualStudentResultsReport()">
                        <tra slug="sa_fr_individual_srr_title"></tra>
                    </a>
                </td>
                <td>
                    <tra slug="sa_fr_individual_srr_desc"></tra>
                </td>
                <td>
                    <ng-container>
                        <button 
                            class="export-button" 
                            (click)="exportIndividualStudentResultsReports()"
                            [disabled]="isDownloadAllDisabled()"
                            [class.is-disabled]="isDownloadAllDisabled()"
                        >
                            <span class="icon"></span>
                            <span class="title">
                                <tra slug="sa_download_all"></tra>
                            </span>
                        </button>
                    </ng-container>
                    <!-- <ng-container *ngIf="isDownloadingAll">
                        <div style="position:relative;">
                            <div class="ball-pulse">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>

                        </div>

                    </ng-container> -->
                </td>
            </tr>
            <tr *ngIf="isMonitoringReport" class="report-row">                
                <td>
                    <a [class.disabled]="markingSession == null" (click)="selectDiscrepancyReport(true)">
                        District Monitoring Report
                    </a>
                </td>
                <td>
                    Use this page to view the district monitoring report
                </td>
                <td>
                   
                </td>
            </tr>
            <tr *ngIf="isMonitoringReport" class="report-row">                
                <td>
                    <a [class.disabled]="markingSession == null" (click)="selectDiscrepancyReport()">
                        Discrepancy Report
                    </a>
                </td>
                <td>
                    Use this page to view the score discrepancy report.
                </td>
                <td>
                   
                </td>
            </tr>
            <tr *ngIf="isFisaAdmin" class="report-row">
                <td>
                    <a (click)="selectDiscrepancyReport(true)">
                        Monitoring Report
                    </a>
                </td>
                <td>
                    Use this page to view the monitoring report.
                </td>
                <td>
                   
                </td>
            </tr>
            <tr *ngIf="auth.isMinistryAdmin() && !isMonitoringReport" class="report-row">                
                <td>
                    <a (click)="selectSelfReflectionReport()">
                        Self Reflection
                    </a>
                </td>
                <td>
                    Use this page to review how students responded to the self reflection.
                </td>
                <td>
                   
                </td>
            </tr>

            <ng-container *ngIf="isFsaEaoMinistry && odiFiles.length > 0 && !isMonitoringReport">
                <tr *ngFor="let odiFile of odiFiles" class="report-row">                
                    <td>
                        {{ odiFile.caption }}
                    </td>
                    <td>
                        {{ odiFile.description }}
                    </td>
                    <td>
                        <a (click)="downloadFile(odiFile.testWindowFileId)" >Download</a>
                    </td>
                </tr>
            </ng-container>

            <tr *ngIf="auth.isMinistryAdmin() && !isEAO() && !isMonitoringReport" class="report-row">
                <td>
                    <a (click)="selectAssessmentKeysReport()">
                        Assessment Keys
                    </a>
                </td>
                <td>
                    Use this page to view and export the assessment keys for FSA.
                </td>
                <td>

                </td>
            </tr>

            <tr *ngIf="auth.isMinistryAdmin()  && isMonitoringReport" class="report-row">                
                <td>
                    <a (click)="selectProvincialResponseReport()">
                        Response Tracker - Provincial Level
                    </a>
                </td>
                <td>
                    Use this page to view student responses at a district level.
                </td>
                <td>
                   
                </td>
            </tr>
            <tr *ngIf="auth.isMinistryAdmin()" class="report-row">                
                <td>
                    School Feedback  Survey
                </td>
                <td>
                    Use export button to export all school feedback for the session.
                </td>
                <td>
                    <button 
                        class="export-button" 
                        (click)="exportAdminFeedbacks()" 
                        [disabled]="isLoading"
                    >
                        <span class="icon"></span>
                        <span class="title">
                            <tra slug="sa_export"></tra>
                        </span>
                    </button>
                </td>
            </tr>
        </table>
    </div>

    <ng-container *ngIf="selectedView">
        <div class="no-district" *ngIf="!districtDetail && [SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT,SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT].includes(selectedView)">
            <tra slug="sa_fr_issr_selectDistrict"></tra>
        </div>

        <ng-container *ngIf="selectedView && testWindow && (districtDetail || selectedView == SaFinalResultsViews.SELF_REFLECTION_REPORT)" [ngSwitch]="selectedView">
            <individual-student-reports *ngSwitchCase="SaFinalResultsViews.INDIVIDUAL_STUDENT_RESULTS_REPORT"
                [districtDetail]="districtDetail" [schoolDetail]="schoolDetail" [testWindow]="testWindow"
                [accountType]="accountType">
            </individual-student-reports>
            <school-level-data-report *ngSwitchCase="SaFinalResultsViews.SCHOOL_LEVEL_DATA_REPORT"
                [testWindow]="testWindow" [districtDetail]="districtDetail" [schoolDetail]="schoolDetail"
                [includeScaled]="sldIncludeScaled" [accountType]="accountType"
                [schools]="schools"    
            >
            </school-level-data-report>
            <fsa-self-reflection *ngSwitchCase="SaFinalResultsViews.SELF_REFLECTION_REPORT" 
                [testWindow]="testWindow">
            </fsa-self-reflection>
            
        </ng-container>
        <ng-container *ngIf="!markingSession && (selectedView == SaFinalResultsViews.DISCREPANCY_REPORT || selectedView == SaFinalResultsViews.MONITORING_REPORT)">
            <p>This report is not available for this administration session.</p>
        </ng-container>
        <ng-container *ngIf="markingSession && selectedView == SaFinalResultsViews.DISCREPANCY_REPORT">
            <view-discrepancy-monitoring-report [markingSessionId]="markingSession.id" [districtDetail]="districtDetail">
                </view-discrepancy-monitoring-report>
        </ng-container>

        <ng-container *ngIf="districtDetail && markingSession && selectedView == SaFinalResultsViews.MONITORING_REPORT">
            <view-discrepancy-monitoring-report [markingSessionId]="markingSession.id" [monitoringOnLoad]="true"
            [districtDetail]="districtDetail">
                </view-discrepancy-monitoring-report>
        </ng-container>

        <ng-container *ngIf="!districtDetail && markingSession && selectedView == SaFinalResultsViews.MONITORING_REPORT && this.auth.isMinistryAdmin()">
            <view-discrepancy-monitoring-report [markingSessionId]="markingSession.id" [monitoringOnLoad]="true"
            [districtDetail]="districtDetail">
                </view-discrepancy-monitoring-report>
        </ng-container>

        <ng-container *ngIf="selectedView && selectedView == SaFinalResultsViews.STUDENT_RESULTS">
            <fsa-student-result 
                [testWindow]="testWindow"
                [districtDetail]="districtDetail" 
                [schoolDetail]="schoolDetail"
            >
            </fsa-student-result>
        </ng-container>

        <ng-container *ngIf="selectedView && selectedView == SaFinalResultsViews.ASSESSMENT_KEYS">
            <fsa-assessment-keys 
                [testWindow]="testWindow"
            >
            </fsa-assessment-keys>
        </ng-container>

        <ng-container *ngIf="selectedView == SaFinalResultsViews.PROFICIENCY_DISTRIBUTION_REPORT">
            <fsa-proficiency-level-report
                [showHeader]="isCurrentUserSchoolAdmin() || isCurrentUserFsaDistrictAdmin()"
                [testWindow]="testWindow"
                [districtDetail]="districtDetail" 
                [schoolDetail]="schoolDetail"
                [headerLayoutEvent]="headerLayoutEvent"
            >
            </fsa-proficiency-level-report>
        </ng-container>
    </ng-container>

</div>